<template>
    <div class="auth">
        <div class="content">
            <div class="wrapper">
                <auth-head></auth-head>
                <router-view/>
            </div>
        </div>
        <div class="banner"></div>          
    </div>
</template>
<script>
import authHead from '@/components/Auth/_head.vue'
export default {
    name: "layout-main",
    components: { authHead },
}
</script>