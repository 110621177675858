<template>
    <div class="auth-head">
        <!-- <change-language></change-language> -->
        <div class="head">
            <div class="brand">
                <img src="@/assets/images/logo.svg" alt="Logo Asuaire">
            </div>
            <div class="detail">
                <p>TP <strong>Reportes</strong></p>
            </div>
        </div>
    </div>
</template>
<script>
// import changeLanguage from '@/components/changeLanguage.vue'
export default {
    name: "auth-head",
    // components: { changeLanguage }
}
</script>